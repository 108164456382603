/* eslint no-console: 0 */
import Vue from 'vue';
import Vuex from 'vuex';
import {
  getWithoutAutherization,
  get,
  post,
  postWithText,
  cancelGetRequest,
} from '@/utils/api';

import {
  translateTextToAllLang,
  translateFormattedTextToAllLang,
} from '@/utils/translate';
import searchStore from './modules/searchStore';
import memberUsers from './modules/memberUsers';
import agencyUsers from './modules/agencyUsers';
import visitorUsers from './modules/visitorUsers';
import socketStore from './modules/socketStore';
import pricePlanStore from './modules/pricePlanStore';
import luggagePriceStore from './modules/luggagePriceStore';

import moduleHome from './modules/regular/moduleHome';

import sabreFo from './sabre/sabreFO';
import bonaufStore from './bonauf/bonaufApp';

const { VUE_APP_LANGUAGES_LIST, VUE_APP_CHANNEL_MOBILE_APP, VUE_APP_LANDING_PAGE_KEY,
  VUE_APP_GOOGLE_PHONE_NUMBER, VUE_APP_FACEBOOK_PHONE_NUMBER, VUE_APP_LANDING_UTN_SOURCE_KEY } = process.env;
const languageList = JSON.parse(VUE_APP_LANGUAGES_LIST);

Vue.use(Vuex);

// TODO:  langCode=HEB|ENG|RUS|ARA

export default new Vuex.Store({
  modules: {
    searchStore,
    moduleHome,
    memberUsers,
    agencyUsers,
    visitorUsers,
    socketStore,
    pricePlanStore,
    sabreFo,
    bonaufStore,
    luggagePriceStore,
  },
  state: {
    developMode: false,
    testMode: false,
    stagingMode: false,
    azureMode: false,
    statusMessage: null,
    categories: null,
    calendarData: null,
    holidayData: null,
    packages: [],
    searchQuery: '',
    currentCategory: null,
    isLoading: false,
    isProcessingBooking: false,
    dateLoading: false,
    subjectCategories: [],
    searchContent: {
      country: '',
      dest: [],
      subject: [],
      month: [],
      from: '',
      to: '',
      adult: 0,
      child: 0,
      infant: 0,
      listAgeChildren: [],
      listAgeInfants: [],
    },
    product: null,
    airport: [],
    roomClass: [],
    roomType: [],
    roomList: [],
    associatedProducts: [],
    bookingInfo: null,
    bookingDataID: '',
    dealData: null,
    isMoreDealData: false,
    language: 'he',
    device: 'desktop',
    verifyingURL: '',
    maccabiVerifyingUrlPrice: [],
    bannerImages: [],
    pageIdForBannerImage: '',
    bannerImagePerDest: '',
    destinationImages: [],
    bannerTimer: [],
    domesticDealData: null,
    isAppDealPage: false,
    appDealData: null,
    isLandingPage: false,
    isAreaPage: false,
    landingInfo: null,
    landingDealData: [],
    landingAreaData: null,
    allLandingAreaData: null,
    showSearchTabCategoryInLanding: null,
    listLandingDestination: null,
    whiteLabel: null,
    footerPagesList: null,
    footerPageContent: null,
    footerCategoryList: null,
    typeChannel: '',
    hotelListByDest: [], // store hotel list by destination
    hotelListByPack: [], // store hotel list by Package type
    filterCondition: {},
    flightId: '',
    currentPage: 'home',
    dealLocation: null,
    searchItemLocation: null,
    // --- start for hotel only ---//
    categoryHotelOnly: {
      packageType: 'HO',
      sort: 4,
      subjectCodes: ['68'],
      destinations: [],
      code: 'Hotel_Only',
      name: {
        en: 'Hotel Only',
        he: 'מלון בלבד',
        ru: 'Hotel Only',
        ar: 'Hotel Only',
      },
    },
    listCountries: [],
    rangeAgeChildren: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    rangeAgeInfants: [0, 1, 2],
    hotelDetails: [],
    facilitiesHotelOnly: [],
    // --- end for hotel only ---//
    sharedShortenUrl: '',
    shredLongUrl: '',
    isCouponSubmitting: false,
    paymentOptionParams: {
      minPaymentsForCredit: 4,
      maxPayments: 18,
    },
    /**
     * Prevent duplicate calling the calendar endpoint
     */
    prevSubjects: '',
    prevDest: '',
  },
  getters: {
    GET_MODE: (state) => state.developMode,
    GET_TEST_MODE: (state) => state.testMode,
    GET_STAGING_MODE: (state) => state.stagingMode,
    GET_AZURE_MODE: (state) => state.azureMode,
    GET_STATUS_MESSAGE: (state) => state.statusMessage,
    GET_CATEGORIES: (state) => state.categories,
    GET_CALENDAR_DATA: (state) => state.calendarData,
    GET_HOLIDAY_DATA: (state) => state.holidayData,
    GET_PACKAGES: (state) => state.packages,
    GET_SEARCH_QUERY: (state) => state.searchQuery,
    GET_CURRENT_CATEGORY: (state) => state.currentCategory,
    GET_LOADING_STATE: (state) => state.isLoading,
    GET_PROCESSING_BOOKING_STATE: (state) => state.isProcessingBooking,
    GET_DATE_LOADING_STATE: (state) => state.dateLoading,
    GET_SUBJECTS_CATEGORY: (state) => state.subjectCategories,
    GET_SEARCH_CONTENT: (state) => state.searchContent,
    GET_PRODUCT: (state) => state.product,
    GET_AIRPORT: (state) => state.airport,
    GET_ROOM_CLASS: (state) => state.roomClass,
    GET_ROOM_TYPE: (state) => state.roomType,
    GET_ROOM_LIST: (state) => state.roomList,
    GET_ASSOCIATED_PRODUCTS: (state) => state.associatedProducts,
    GET_BOOKING_INFO: (state) => state.bookingINfo,
    GET_BOOKING_DATA_ID: (state) => state.bookingDataID,
    GET_HOTEL_STATUS_IN_PAYMENT_PAGE: (state) => state.hotelStatusInPaymentPage,
    GET_DEAL_DATA: (state) => state.dealData,
    GET_IS_MORE_DEAL_DATA: (state) => state.isMoreDealData,
    GET_LANGUAGE: (state) => state.language,
    GET_DEVICE: (state) => state.device,
    GET_STATE_MOBILE_APP: (state) => state.typeChannel === VUE_APP_CHANNEL_MOBILE_APP,
    GET_VERIFYING_URL: (state) => state.verifyingURL,
    GET_MACCABI_VERIFYING_URLS: (state) => state.maccabiVerifyingUrlPrice,
    GET_BANNER_IMAGES: (state) => state.bannerImages,
    GET_BANNER_IMAGE_DEST: (state) => state.bannerImagePerDest,
    GET_DESTINATION_IMAGES: (state) => state.destinationImages,
    GET_BANNER_TIMER: (state) => state.bannerTimer,
    GET_DOMESTIC_DEAL_DATA: (state) => state.domesticDealData,
    GET_IS_APP_DEAL_PAGE: (state) => state.isAppDealPage,
    GET_APP_DEAL_DATA: (state) => state.appDealData,
    GET_IS_LANDING_PAGE: (state) => state.isLandingPage,
    GET_IS_AREA_PAGE: (state) => state.isAreaPage,
    GET_LANDING_INFO: (state) => state.landingInfo,
    GET_LANDING_DEAL_DATA: (state) => state.landingDealData,
    GET_LANDING_AREA_DATA: (state) => state.landingAreaData,
    GET_ALL_LANDING_AREA_DATA: (state) => state.allLandingAreaData,
    GET_LANDING_DESTINATIONS: (state) => state.listLandingDestination,
    GET_SHOW_SEARCH_TAB_CATEGORY_FOR_LANDING: (state) => state.showSearchTabCategoryInLanding,
    GET_WHITE_LABEL_DATA: (state) => state.whiteLabel,
    GET_FOOTER_PAGES_LIST: (state) => state.footerPagesList,
    GET_FOOTER_PAGE_CONTENT: (state) => state.footerPageContent,
    GET_FOOTER_CATEGORY_LIST: (state) => state.footerCategoryList,
    GET_TYPE_CHANNEL: (state) => state.typeChannel,
    GET_HOTEL_LIST_BY_DESTINATION: (state) => state.hotelListByDest,
    GET_HOTEL_LIST_BY_PACK: (state) => state.hotelListByPack,
    GET_FILTER_CONDITION: (state) => state.filterCondition,
    GET_FLIGHT_ID: (state) => state.flightId,
    GET_CURRENT_PAGE: (state) => state.currentPage,
    GET_DEAL_LOCATION: (state) => state.dealLocation,
    GET_SEARCH_ITEM_LOCATION: (state) => state.searchItemLocation,
    // --- start for hotel only ---//
    GET_HOTEL_ONLY: (state) => state.categoryHotelOnly,
    GET_LIST_COUNTRIES: (state) => state.listCountries,
    GET_RANGE_AGE_CHILDREN: (state) => state.rangeAgeChildren,
    GET_RANGE_AGE_INFANTS: (state) => state.rangeAgeInfants,
    GET_HOTEL_DETAIL_DATA: (state) => state.hotelDetails,
    GET_FACILITIES_HOTEL_ONLY: (state) => state.facilitiesHotelOnly,
    // --- end for hotel only ---//
    GET_SHARED_SHORTEN_URL: (state) => state.sharedShortenUrl,

    GET_IS_COUPON_SUBMITTING_STATE: (state) => state.isCouponSubmitting,
    GET_PAYMENT_OPTION_PARAMS: (state) => state.paymentOptionParams,
  },
  mutations: {
    SET_MODE: (state, payload) => {
      state.developMode = payload;
    },
    SET_TEST_MODE: (state, payload) => {
      state.testMode = payload;
    },
    SET_STAGING_MODE: (state, payload) => {
      state.stagingMode = payload;
    },
    SET_AZURE_MODE: (state, payload) => {
      state.azureMode = payload;
    },
    SET_STATUS_MESSAGE: (state, payload) => {
      state.statusMessage = payload;
    },
    SET_CATEGORIES: (state, payload) => {
      state.categories = payload;
    },
    SET_CALENDAR_DATA: (state, payload) => {
      state.calendarData = payload;
    },
    SET_HOLIDAY_DATA: (state, payload) => {
      state.holidayData = payload;
    },
    SET_PACKAGES: (state, payload) => {
      state.packages = payload;
    },
    SET_SEARCH_QUERY: (state, payload) => {
      state.searchQuery = payload;
    },
    SET_CURRENT_CATEGORY: (state, payload) => {
      // if (!state.categories) return;
      let currCate = state.categories?.find((item) => (item.code === payload));
      if (!currCate && payload === 'Hotel_Only') currCate = state.categoryHotelOnly;
      state.currentCategory = currCate;
    },
    SET_LOADING_STATE: (state, payload) => {
      state.isLoading = payload;
    },
    SET_PROCESSING_BOOKING_STATE: (state, payload) => {
      state.isProcessingBooking = payload;
    },
    SET_DATE_LOADING_STATE: (state, payload) => {
      state.dateLoading = payload;
    },
    SET_SUBJECT_CATEGORY: (state, payload) => {
      state.subjectCategories = payload;
    },
    SET_SEARCH_ITEM_CONTENT: (state, payload) => {
      state.searchContent[payload[0]] = payload[1];
      if (payload[0] === 'child') state.searchContent.listAgeChildren.splice(payload[1] + 1);
      if (payload[0] === 'infant') state.searchContent.listAgeInfants.splice(payload[1] + 1);
    },
    SET_RESET_SEARCH_CONTENT: (state) => {
      state.searchContent = {
        country: '',
        dest: [],
        subject: [],
        month: [],
        from: '',
        to: '',
        adult: 0,
        child: 0,
        infant: 0,
        listAgeChildren: [],
        listAgeInfants: [],
      };
    },
    SET_PRODUCT: (state, payload) => {
      state.product = payload;
    },
    SET_AIRPORT: (state, payload) => {
      state.airport = payload;
    },
    SET_ROOM_CLASS: (state, payload) => {
      state.roomClass = payload;
    },
    SET_ROOM_TYPE: (state, payload) => {
      state.roomType = payload;
    },
    SET_ROOM_LIST: (state, payload) => {
      state.roomList = payload;
    },
    SET_ASSOCIATED_PRODUCTS: (state, payload) => {
      state.associatedProducts = payload;
    },
    SET_BOOKING_INFO: (state, payload) => {
      state.bookingInfo = payload;
    },
    SET_BOOKING_DATA_ID: (state, payload) => {
      state.bookingDataID = payload;
    },
    SET_DEAL_DATA: (state, payload) => {
      if (!state.dealData) state.dealData = [];
      state.dealData.push(...payload);
    },
    SET_MORE_DEAL_DATA: (state, payload) => {
      state.isMoreDealData = payload;
    },
    SET_LANGUAGE: (state, payload) => {
      state.language = payload;
    },
    SET_DEVICE: (state, payload) => {
      state.device = payload;
    },
    SET_VERIFYING_URL: (state, payload) => {
      state.verifyingURL = payload;
    },
    SET_MACCABI_VERIFYING_URLS: (state, payload) => {
      state.maccabiVerifyingUrlPrice = payload;
    },
    SET_BANNER_IMAGES: (state, payload) => {
      state.bannerImages = payload;
    },
    SET_PAGE_ID_FOR_BANNER_IMAGES: (state, payload) => {
      state.pageIdForBannerImage = payload;
    },
    SET_BANNER_IMAGE_DEST: (state, payload) => {
      state.bannerImagePerDest = payload;
    },
    SET_DESTINATION_IMAGES: (state, payload) => {
      state.destinationImages = payload;
    },
    SET_BANNER_TIMER: (state, payload) => {
      state.bannerTimer = payload;
    },
    SET_DOMESTIC_DEAL_DATA: (state, payload) => {
      state.domesticDealData = payload;
    },
    SET_APP_DEAL_PAGE: (state, payload) => {
      state.isAppDealPage = payload;
    },
    SET_APP_DEAL_DATA: (state, payload) => {
      state.appDealData = payload;
    },
    SET_LANDING_PAGE: (state, payload) => {
      state.isLandingPage = payload;
    },
    SET_AREA_PAGE: (state, payload) => {
      state.isAreaPage = payload;
    },
    SET_LANDING_INFO: (state, payload) => {
      state.landingInfo = payload;
    },
    SET_LANDING_DEAL_DATA: (state, payload) => {
      state.landingDealData = payload;
    },
    SET_LANDING_AREA_DATA: (state, payload) => {
      state.landingAreaData = payload;
    },
    SET_ALL_LANDING_AREA_DATA: (state, payload) => {
      state.allLandingAreaData = payload;
    },
    SET_LANDING_DESTINATIONS: (state, payload) => {
      state.listLandingDestination = payload;
    },
    SET_SEARCH_TAB_CATEGORY_FOR_LANDING: (state, payload) => {
      if (payload === null) {
        state.showSearchTabCategoryInLanding = null;
        return;
      }
      const { packCategDests } = payload;

      const noneNullCategories = Object.keys(packCategDests).filter((key) => packCategDests[key] && packCategDests[key].length);

      const categoryId = (noneNullCategories.includes('vacation_pack')) ? 'vacation_pack' : noneNullCategories[0];

      state.showSearchTabCategoryInLanding = categoryId;
    },
    SET_WHITE_LABEL: (state, payload) => {
      state.whiteLabel = payload;
    },
    SET_FOOTER_PAGES_LIST: (state, payload) => {
      state.footerPagesList = (payload === undefined || payload === '') ? null : payload;
    },
    SET_FOOTER_PAGE_CONTENT: (state, payload) => {
      state.footerPageContent = (payload === undefined || payload === '') ? null : payload;
    },
    SET_FOOTER_CATEGORY_LIST: (state, payload) => {
      state.footerCategoryList = (payload === undefined || payload === '') ? null : payload;
    },
    // SET_SUB_AGENCY: (state, payload) => {
    //   state.subAgency = payload === undefined ? '' : payload;
    // },
    SET_TYPE_CHANNEL: (state, payload) => {
      state.typeChannel = payload?.[0] || payload;
    },
    SET_HOTEL_LIST_BY_DEST: (state, payload) => {
      state.hotelListByDest = payload;
    },
    SET_HOTEL_LIST_BY_PACK: (state, payload) => {
      state.hotelListByPack = payload;
    },
    SET_FILTER_CONDITION: (state, payload) => {
      const { key, value } = payload;
      state.filterCondition[key] = value;
    },
    SET_FLIGHT_ID: (state, payload) => {
      state.flightId = payload;
    },
    SET_CURRENT_PAGE: (state, payload) => {
      state.currentPage = payload;
    },
    SET_DEAL_LOCATION: (state, payload) => {
      state.dealLocation = payload;
    },
    SET_SEARCH_ITEM_LOCATION: (state, payload) => {
      state.searchItemLocation = payload;
    },
    // --- start for hotel only ---//
    SET_LIST_COUNTRIES: (state, payload) => {
      state.listCountries = payload;
    },
    SET_HOTELONLY_DESTINATION: (state, payload) => {
      state.categoryHotelOnly.destinations = payload;
    },
    SET_HOTEL_DETAIL_DATA: (state, payload) => {
      state.hotelDetails = state.hotelDetails.concat(payload);
    },
    SET_FACILITIES_HOTEL_ONLY: (state, payload) => {
      state.facilitiesHotelOnly = payload;
    },
    SET_SHARED_SHORTEM_URL: (state, payload) => {
      state.sharedShortenUrl = payload;
    },
    SET_SHARED_LONG_URL: (state, payload) => {
      state.shredLongUrl = payload;
    },
    SET_IS_COUPON_SUBMIT_STATE: (state, payload) => {
      state.isCouponSubmitting = payload;
    },
    SET_PAYMENT_OPTION_PARAMS: (state, payload) => {
      state.paymentOptionParams = payload;
    },
    /**
     * Prevent duplicate calling the calendar endpoint
     */
    SET_PREV_SUBJECTS: (state, payload) => {
      state.prevSubjects = payload;
    },
    SET_PREV_DESTS: (state, payload) => {
      state.prevDest = payload;
    },
  },
  actions: {
    UPDATE_VERIFYING_URL: (context, payload) => {
      context.commit('SET_VERIFYING_URL', payload);
    },
    UPDATE_MACCABI_VERIFYING_URL: (context, payload) => {
      context.commit('SET_MACCABI_VERIFYING_URLS', payload);
      window.sessionStorage.setItem('maccabi-info', JSON.stringify(payload));
    },
    UPDATE_DEVICE: (context, payload) => {
      context.commit('SET_DEVICE', payload);
    },
    UPDATE_LANGUAGE: (context, payload) => {
      context.commit('SET_LANGUAGE', payload);
    },
    UPDATE_CURRENT_CATEGORY: (context, payload) => {
      const {
        categoryId,
      } = payload;
      context.commit('SET_CURRENT_CATEGORY', categoryId);
    },
    UPDATE_ROOM_LIST: (context, payload) => {
      const {
        roomList,
      } = payload;
      context.commit('SET_ROOM_LIST', roomList);
      window.sessionStorage.setItem('roomList', JSON.stringify(payload));
    },
    FETCH_STATUS_MESSAGE: async (context) => {
      try {
        const response = await getWithoutAutherization('MAINTENANCE', '/maintenance/message');
        context.commit('SET_STATUS_MESSAGE', response.data);
        return response.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    FETCH_CATEGORIES_LIST: async (context) => {
      const { marketerId, agencyAuthorized } = context.state.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `&agency=${marketerId}` : '';
      try {
        const response = await get('BFF', `/operation/PackCategoryTop?langCode=${languageList[context.state.language]}${paramMarketerId}`, context);
        context.commit('SET_CATEGORIES', response.data);
        return response.data;
      } catch (e) {
        if (agencyAuthorized && e.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        return null;
      }
    },
    FETCH_CALENDAR_DATA: async (context, payload) => {
      const { subjects, dest } = payload;
      const { marketerId, agencyAuthorized } = context.state.agencyUsers;
      const paramSubject = subjects.map((sub) => (`subjectCode=${sub}`)).join('&');
      const paramDest = Array.isArray(dest) ? dest.map((d) => (`destCode=${d}`)).join('&') : `destCode=${dest}`;
      const paramMarketerId = marketerId !== '' ? `&agency=${marketerId}` : '';

      /**
       * Prevent duplicate calling the calendar endpoint
       */
      if (context.state.prevSubjects === paramSubject && context.state.prevDest === paramDest) {
        return;
      }

      context.commit('SET_PREV_SUBJECTS', paramSubject);
      context.commit('SET_PREV_DESTS', paramDest);
      context.commit('SET_DATE_LOADING_STATE', true);
      try {
        // const response = await get('BFF', `/operation/packDestsAndDates?langCode=${languageList[context.state.language]}`, context);
        const response = await get('BFF', `/operation/packDates/bySubjectsCities?langCode=${languageList[context.state.language]}&${paramSubject}&${paramDest}${paramMarketerId}&inclNoSeatDates=true`, context);
        context.commit('SET_CALENDAR_DATA', response.data);
      } catch (e) {
        if (agencyAuthorized && e.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
      }
      context.commit('SET_DATE_LOADING_STATE', false);
    },
    FETCH_HOLIDAY_DATA: async (context, payload) => {
      const { marketerId, agencyAuthorized } = context.state.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `&agency=${marketerId}` : '';
      context.commit('SET_DATE_LOADING_STATE', true);
      try {
        const response = await get('BFF', `/operation/packDates/holidays?year=${payload}&year=${payload + 1}${paramMarketerId}`, context);
        const holidays = [...response.data[0].holidays, ...response.data[1].holidays];

        context.commit('SET_HOLIDAY_DATA', holidays);
      } catch (e) {
        if (agencyAuthorized && e.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
      }
      context.commit('SET_DATE_LOADING_STATE', false);
    },
    FETCH_SUBJECT_CATEGORY: async (context) => {
      context.commit('SET_LOADING_STATE', true);
      // pending call endpoint
      const response = await get('BFF', '/packSubCategory', context);
      const data = await response.data;
      context.commit('SET_SUBJECT_CATEGORY', data.data);
      context.commit('SET_LOADING_STATE', false);
      return data;
    },
    FETCH_STOP: (context) => {
      if (context.state.isLoading) {
        cancelGetRequest();
      }
    },
    FETCH_PACKAGE_BY_SEARCH: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const {
        dest,
        hotelId,
        yearMonth,
        subjectIds,
        fromDate,
        toDate,
        categoryId,
        adult,
        child,
        infant,
        subCategoryId,
      } = payload;
      const { marketerId, agencyAuthorized, selectedOdysseyAgentCode } = context.state.agencyUsers;
      const paramMarketerId = marketerId ? `&agency=${marketerId}` : '';
      const paramOdyAgentCode = selectedOdysseyAgentCode ? `&odyAgentCode=${selectedOdysseyAgentCode}` : '';
      let searchKind = 'search', destUrl = '', dateUrl = '', subjectUrl = '';

      let orgRequestBody = {};

      try {
        if (categoryId === 'Organize_tour_packages') {
          const dests = decodeURIComponent(dest).split(',');
          const hotelIds = decodeURIComponent(hotelId).split(',');

          const hotelIdsByDest = {};
          dests.forEach((key, inx) => {
            if (!hotelIdsByDest[key]) {
              hotelIdsByDest[key] = [+hotelIds[inx]];
            } else {
              hotelIdsByDest[key].push(+hotelIds[inx]);
            }
          });

          const groupIds = !subjectIds ? null : subjectIds.split(',');
          const eachSubjectIds = groupIds
            ? groupIds.reduce((prev, curr) => prev.concat(context.state.subjectCategories.find((item) => item.id === curr)?.subjectCodes || []), []) : null;
          // subjectUrl = eachSubjectIds ? `&subject=${eachSubjectIds.join('&subject=')}` : '';

          orgRequestBody = {
            hotelIdsByDest,
            subject: eachSubjectIds,
            yearMonth: yearMonth.split(','),
            categoryId,
            // subCategoryId: subCategoryId.split(','),
            adult: +adult,
            child: +child,
            infant: +infant,
            odyAgentCode: selectedOdysseyAgentCode,
          };

          searchKind = 'aggSearchByHotels';
          // destUrl = `?dest=${decodeURIComponent(dest).split(',').join('&dest=')}`;
          // dateUrl = `&yearMonth=${yearMonth.split(',').join('&yearMonth=')}`;
        } else if (categoryId === 'sport_pack') {
          searchKind = 'aggSearch';
          destUrl = `?dest=${decodeURIComponent(dest).split(',').join('&dest=')}`;
          dateUrl = `&yearMonth=${yearMonth.split(',').join('&yearMonth=')}`;
          const listSubjectIds = subjectIds.split(',');
          subjectUrl = `&subject=${listSubjectIds.join('&subject=')}`;
        } else {
          searchKind = 'search';
          destUrl = `?dest=${decodeURIComponent(dest)}`;
          dateUrl = `&fromDate=${fromDate}&toDate=${toDate}`;

          const groupIds = !subCategoryId ? null : subCategoryId.split(',');
          subjectUrl = groupIds ? `&subCategoryId=${groupIds.join('&subCategoryId=')}` : '';
        }

        let response = null;
        context.commit('SET_CURRENT_CATEGORY', categoryId);
        if (categoryId === 'Organize_tour_packages') {
          response = await post('BFF', `/operation/${searchKind}/?langCode=${languageList[context.state.language]}${paramMarketerId}`, orgRequestBody, context);
        } else {
          response = await get('BFF',
            `/operation/${searchKind}${destUrl}${dateUrl}${subjectUrl}&service=&categoryId=${categoryId}&adult=${adult}&child=${child || 0}&infant=${infant || 0}&getLowestPrc=1&langCode=${languageList[context.state.language]}${paramMarketerId}${paramOdyAgentCode}`, context);
        }
        context.commit('SET_PACKAGES', response?.data || null);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (error) {
        const body = `<p>${JSON.stringify(payload)}</p>
        <h3>${`/operation/${searchKind}${destUrl}${dateUrl}${subjectUrl}&service=&categoryId=${categoryId}&adult=${adult}&child=${child || 0}&infant=${infant || 0}&getLowestPrc=1&langCode=${languageList[context.state.language]}${paramMarketerId}${paramOdyAgentCode}`}</h3></Br>
        <pre>${error.response.data.title || error.response.data}</pre>
        `;
        const notification = {
          subject: `Checking error status for search result page - ${error.response.status}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        return null;
      }
    },
    SET_SEARCH_ITEM: async (context, payload) => {
      context.commit('SET_SEARCH_ITEM_CONTENT', payload);
    },
    RESET_SEARCH_CONTENT: async (context) => {
      context.commit('SET_RESET_SEARCH_CONTENT');
    },
    FETCH_PRODUCT: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      // const {
      //   packId,
      //   laId,
      //   dateFrom,
      //   flights,
      //   categoryId,
      //   dealTypeCode,
      //   channel,
      //   dealId,
      // } = payload;
      const packId = payload.packId || payload.packid;
      const laId = payload.laId || payload.laid;
      const dateFrom = payload.dateFrom || payload.datefrom;
      const { flights, channel } = payload;
      const categoryId = payload.categoryId || payload.categoryid;
      const dealTypeCode = payload.dealTypeCode || payload.dealtypecode;
      const dealId = payload.dealId || payload.dealid;

      context.commit('SET_CURRENT_CATEGORY', categoryId);

      await context.dispatch('FETCH_ROOM_CLASS');
      await context.dispatch('FETCH_ROOM_TYPE');
      const { marketerId, subAgency, agencyAuthorized, selectedOdysseyAgentCode } = context.state.agencyUsers;
      const paramMarketerId = marketerId ? `&agency=${marketerId}` : '';
      const paramSubAgency = subAgency ? `&subAgency=${subAgency}` : '';
      const paramChannel = channel ? `&channel=${channel}` : '';
      const paramDealId = dealId ? `&dealId=${dealId}` : '';
      const paramOdyAgentCode = selectedOdysseyAgentCode ? `&odyAgentCode=${selectedOdysseyAgentCode}` : '';
      const paramDealTypeCode = dealTypeCode !== '' && dealTypeCode !== undefined ? `&dealTypeCode=${dealTypeCode}` : '';
      try {
        const response = await get('BFF',
          `/operation/select?packId=${packId}&laId=${laId}&dateFrom=${dateFrom}&flights=${flights}&langCode=${languageList[context.state.language]}${paramMarketerId}${paramDealTypeCode}${paramSubAgency}${paramChannel}${paramDealId}${paramOdyAgentCode}`, context);

        // translate some properties
        // notesTranslation
        if (response.data?.notesTranslation?.he) {
          const translatedNotes = translateFormattedTextToAllLang(response.data.notesTranslation.he);
          translatedNotes.forEach(({ text, to }) => {
            if (!response.data?.notesTranslation?.[to]) {
              response.data.notesTranslation[to] = text;
            }
          });
        }

        // remarkTranslation
        if (response.data?.remarkTranslation?.he) {
          const translatedRemark = translateTextToAllLang(response.data.remarkTranslation.he);
          translatedRemark.forEach(({ text, to }) => {
            if (!response.data?.remarkTranslation?.[to]) {
              response.data.remarkTranslation[to] = text;
            }
          });
        }
        context.commit('SET_PRODUCT', response.data);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (error) {
        const body = `<p>${JSON.stringify(payload)}</p>
        <h3>${`/operation/select?packId=${packId}&laId=${laId}&dateFrom=${dateFrom}&flights=${flights}&langCode=${languageList[context.state.language]}${paramMarketerId}${paramDealTypeCode}${paramSubAgency}${paramChannel}${paramDealId}${paramOdyAgentCode}`}</h3></Br>
        <pre>${typeof (error) === 'string' ? error : JSON.stringify(error)}</pre>
        `;
        const notification = {
          subject: `Checking error status for fetching product - ${error.response?.status}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        return null;
      }
    },
    FETCH_AIRPORT: async (context) => {
      context.commit('SET_LOADING_STATE', true);
      try {
        const response = await get('BFF', '/airport/activeOnly', context);
        context.commit('SET_AIRPORT', response.data);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (error) {
        context.commit('SET_LOADING_STATE', false);
        return [];
      }
    },
    FETCH_PRODUCT_FLIGHT_ONLY: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const {
        packId,
        dateFrom,
        flights,
        categoryId,
        dealTypeCode,
        channel,
        dealId,
      } = payload;
      context.commit('SET_CURRENT_CATEGORY', categoryId);
      const { marketerId, subAgency, agencyAuthorized, selectedOdysseyAgentCode } = context.state.agencyUsers;
      const paramMarketerId = marketerId ? `&agency=${marketerId}` : '';
      const paramSubAgency = subAgency ? `&subAgency=${subAgency}` : '';
      const paramChannel = channel ? `&channel=${channel}` : '';
      const paramDealId = dealId ? `&dealId=${dealId}` : '';
      const paramDealTypeCode = dealTypeCode !== '' && dealTypeCode !== undefined ? `&dealTypeCode=${dealTypeCode}` : '';
      const paramOdyAgentCode = selectedOdysseyAgentCode ? `&odyAgentCode=${selectedOdysseyAgentCode}` : '';
      try {
        const response = await get('BFF', `/operation/select?packId=${packId}&dateFrom=${dateFrom}&flights=${flights}&langCode=${languageList[context.state.language]}${paramMarketerId}${paramDealTypeCode}${paramSubAgency}${paramChannel}${paramDealId}${paramOdyAgentCode}`, context);
        context.commit('SET_PRODUCT', response.data);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (e) {
        if (agencyAuthorized && e.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        return null;
      }
    },
    FETCH_ROOM_CLASS: async (context) => {
      const response = await get('BFF', `/roomClass?from=1&to=10000&langCode=${languageList[context.state.language]}&langCode=${languageList[context.state.language]}`, context);
      context.commit('SET_ROOM_CLASS', response.data.data);
      return response.data;
    },
    FETCH_ROOM_TYPE: async (context) => {
      const response = await get('BFF', `/roomCompositionType/fcapi?langCode=${languageList[context.state.language]}`, context);
      context.commit('SET_ROOM_TYPE', response.data);
      return response;
    },
    FETCH_ROOM_BASIS_CONJUGATE: async (context, payload) => {
      const response = await get('BFF', `/operation/conjugatePackages/${payload}`, context);
      return response.data;
    },
    CALCULATION_CURRENCY_CONVERT: async (context, payload) => {
      const {
        ConvertFrom,
        ConvertTo,
        AmountToConvert,
      } = payload;
      const response = await get('BFF', `/CurrencyConvert?AmountToConvert=${AmountToConvert}&ConvertFrom=${ConvertFrom}&ConvertTo=${ConvertTo}&langCode=${languageList[context.state.language]}`, context);
      return response.data;
    },
    FETCH_PRICES_BY_CLASS_TYPE: async (context, payload) => {
      const {
        packId,
        hotelid,
        packSelectionId,
        cls,
        typ,
        dateArv,
        infant,
        flights,
        adult,
        child,
        checkAvl,
      } = payload;
      const { marketerId, agencyAuthorized, selectedOdysseyAgentCode, isMaccabiAgency } = context.state.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `&agency=${marketerId}` : '';
      const paramOdyAgentCode = selectedOdysseyAgentCode ? `&odyAgentCode=${selectedOdysseyAgentCode}` : '';
      const dateParam = isMaccabiAgency ? '' : `&dateArv=${dateArv}`;
      const requestUrl = `/operation/CalcPack?packId=${packId}&hotelid=${hotelid}&packSelectionId=${packSelectionId}&cls=${cls}&typ=${typ}${dateParam}&adult=${adult}&child=${child}&infant=${infant}&flights=${flights}&checkAvl=${checkAvl || false}&langCode=${languageList[context.state.language]}${paramMarketerId}${paramOdyAgentCode}`;

      try {
        const response = await get('BFF', requestUrl, context);
        if (response.data.notAllowedExcessviveDiscount) {
          const b = { ...response };
          const url = window.location.href;
          const body = `<p>${JSON.stringify(payload)}</p>
              <h3>${`/operation/CalcPack?packId=${packId}&hotelid=${hotelid}&packSelectionId=${packSelectionId}&cls=${cls}&typ=${typ}${dateParam}&adult=${adult}&child=${child}&infant=${infant}&flights=${flights}&langCode=${languageList[context.state.language]}${paramMarketerId}${paramOdyAgentCode}`}</h3></Br>
              <pre>${JSON.stringify(b)}</pre>
              <pre>${url}</pre>
              `;
          const notification = {
            subject: 'Warning for the discount of room',
            body,
          };
          context.dispatch('NOTIFY_ADMIN', notification);
        }
        return response.data;
      } catch (error) {
        const body = `<p>${JSON.stringify(payload)}</p>
        <h3>${requestUrl}</h3></Br>
        <pre>${typeof (error.response?.data) === 'string' ? error.response?.data : JSON.stringify(error.response?.data)}</pre>
        `;
        const notification = {
          subject: `Checking error status for calc pack - ${error.response.status}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        return 'error';
      }
    },
    FETCH_ASSOCIATED_PRODUCTS: async (context, payload) => {
      const { hotelId, category, dest } = payload;

      try {
        let response = null;
        if (category === 'Flight_Only') {
          response = await get('BFF', `/deal/associated?packDestination=${dest}&packageCategoryId=${category}`, context);
        } else {
          response = await get('BFF', `/deal/associated?hotelId=${hotelId}&packageCategoryId=${category}`, context);
        }

        context.commit('SET_ASSOCIATED_PRODUCTS', response.data);
        return response.data;
      } catch (error) {
        return 'error';
      }
    },

    STORE_BOOKING_DATA: async (context, payload) => {
      window.sessionStorage.setItem('BookingDetailInfo', JSON.stringify(payload));
      context.commit('SET_BOOKING_INFO', payload);
    },
    SEND_BOOKING_DATA: async (context, payload) => {
      const bookingInfo = {
        packageId: -1,
        hotel_shift_1: 0,
        duration: 0,
        discountPercent: null,
        price: 0,
        priceNIS: 0,
        currency: 'US',
        paxList: [],
        packSelectionId: '',
        outwardFlight: {
          flightId: '',
          departureCityCode: '',
          departureDate: '1900-01-01',
          departureTime: '00:00',
          arrivalCity: '',
          class: '',
          paxIds: [],
        },
        inwardFlight: {
          flightId: '',
          departureCityCode: '',
          departureDate: '1900-01-01',
          departureTime: '00:00',
          arrivalCity: '',
          class: '',
          paxIds: [],
        },
        hotel: [],
        eventTicketReservation: null,
        // couponCode: null,
        couponCodes: [],
        payer: {
          firstName: '',
          lastName: '',
          email: '',
          mobile: '',

          CreditCardExpirMonth: '',
          CreditCardExpirYear: '',
          IdenticationNumber: '',
          NoOfPayment: 1,
        },
        sendPromotionalProductMails: true,
        sendPromotionalInsuranceMails: true,
        sendEsimOffer: true,
        ensureCancellation: false,
        flyingSafetyAssurance: false,
        // NoCostFlyingSafety: false,
        OdyAgentCodeByAgent: '',
        remarkByAgent: '',
        DiscountAmountByAgent: 0,
        BypassPaymentByAgent: false,
        additionalServices: null,
      };
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(bookingInfo)) {
        try {
          if (payload.data[key] !== undefined) {
            bookingInfo[key] = payload.data[key];
          }
        } catch (error) {
          Vue.$log.debug('error');
        }
      }
      payload.data = bookingInfo;
      context.dispatch('STORE_BOOKING_DATA', payload);
      const { marketerId, agencyAuthorized } = context.state.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `&agency=${marketerId}` : '';
      const dataID = payload.sendingStage === 'init' ? '' : context.state.bookingDataID || window.sessionStorage.getItem('BookingDataID') || '';
      try {
        const response = await post('BFF', `/operation/storePage/${dataID}?langCode=${languageList[context.state.language]}${paramMarketerId}`, bookingInfo, context);
        context.commit('SET_BOOKING_DATA_ID', response.data.bookingTransactionId);
        return {
          success: true,
          data: response.data,
        };
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);

        const body = `<p>${JSON.stringify(payload)}</p>
        <h3>${`/operation/storePage/${dataID}?langCode=${languageList[context.state.language]}${paramMarketerId}`}</h3></Br>
        <pre>${JSON.stringify(error.response)}</pre>
        `;
        const notification = {
          subject: `Error status for getting store page - ${error.response.status}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);

        return {
          success: false,
          error,
        };
      }
    },
    CHECK_ROOM_STATUS_IN_CARD_PAGE: async (context) => {
      const payload = JSON.parse(window.sessionStorage.getItem('BookingDetailInfo'));
      const { marketerId, agencyAuthorized } = context.state.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `&agency=${marketerId}` : '';
      try {
        const response = await post('BFF', `/operation/chkInv?langCode=${languageList[context.state.language]}${paramMarketerId}`, payload.data, context);

        return response.data;
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);

        const body = `<p>${JSON.stringify(payload)}</p>
        <h3>${`/operation/chkInv?langCode=${languageList[context.state.language]}${paramMarketerId}`}</h3></Br>
        <pre>${JSON.stringify(error.response)}</pre>
        `;
        const notification = {
          subject: `Error status for getting status in store page - ${error.response.status}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);

        return false;
      }
    },
    GET_PAYMENT_URL: async (context, payload) => {
      const { marketerId, agencyAuthorized, isMaccabiAgency } = context.state.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `&agency=${marketerId}` : '';
      const retryState = payload.retryBook ? `&retry=${payload.retryBook}` : '';
      try {
        const response = await post('BFF', `/operation/getPaymentUrl/${payload.bookID}?langCode=${languageList[context.state.language]}${paramMarketerId}${retryState}`, payload.payerInfo, context);
        return {
          success: true,
          url: response.data,
        };
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        if (isMaccabiAgency) {
          context.dispatch('NOTIFY_ADMIN', {
            subject: `Error trace email to check getPaymentUrl - ${error.response.status}`,
            body: `<p>${payload.bookID}</p>
                  <pre>${JSON.stringify(payload.bookData)}</pre>
                  <br/>
                  <pre>${payload.splitAmounts.join(',')}</pre>
                  <br/>
                  <pre>${JSON.stringify(error.response)}</pre>`,
          });
        }
        return {
          success: false,
          error,
        };
      }
    },
    GET_SUCCEED_BOOKING: async (context) => {
      context.commit('SET_PROCESSING_BOOKING_STATE', true);
      const BookingDataID = window.sessionStorage.getItem('BookingDataID');
      const response = await get('BFF', `/operation/succeededBooking/${BookingDataID}?langCode=${languageList[context.state.language]}`, context);
      context.commit('SET_PROCESSING_BOOKING_STATE', false);
      return response;
    },
    GET_BOOKED_INFO: async (context, payload) => {
      context.commit('SET_PROCESSING_BOOKING_STATE', true);
      const { marketerId, bypassPayment, isMaccabiAgency } = context.state.agencyUsers;
      const BookingDataID = window.sessionStorage.getItem('BookingDataID');
      const paramPaymentUid = bypassPayment ? '' : `&paymentTransUid=${payload.paymentTransUid}`;
      const paramMarketerId = (typeof marketerId !== 'undefined' && marketerId !== '') ? `&agency=${marketerId}` : '';
      const paramRetryState = payload.retryState ? `&retry=${payload.retryState}` : '';
      const { maccabiCardIndex } = payload;

      const requestUrl = isMaccabiAgency && maccabiCardIndex
        ? `/operation/debitCreditCard/${BookingDataID}/${maccabiCardIndex}?langCode=${languageList[context.state.language]}${paramPaymentUid}${paramMarketerId}${paramRetryState}`
        : `/operation/debitCreditCard/${BookingDataID}?langCode=${languageList[context.state.language]}${paramPaymentUid}${paramMarketerId}${paramRetryState}`;

      try {
        const response = await post('BFF', requestUrl, undefined, context);
        context.commit('SET_BOOKING_DATA_ID', response.data.bookingTransactionId);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        return response;
      } catch (error) {
        context.commit('SET_LOADING_STATE', false);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);

        const body = `<p>${JSON.stringify(payload)}</p>
        <h3>${requestUrl}</h3></Br>
        <pre>${JSON.stringify(error.response)}</pre>
        `;
        const notification = {
          subject: `Error status for getting debitCreditCard - ${error.response.status}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);

        return error;
      }
    },
    FETCH_COUPON_INFO: async (context, payload) => {
      const { code, originalPrice, checkInDate, currency, numOfPax } = payload;
      const { marketerId } = context.state.agencyUsers;
      const paramMarketerId = (typeof marketerId !== 'undefined' && marketerId !== '') ? `&agency=${marketerId}` : '';
      const bookTransId = context.state.bookingDataID || window.sessionStorage.getItem('BookingDataID');
      let response = null;
      context.commit('SET_IS_COUPON_SUBMIT_STATE', true);
      try {
        const url = `/operation/checkCoupon/${code}?amount=${originalPrice}&checkinDate=${checkInDate}&currency=${currency}&bookTransId=${bookTransId}&numOfPax=${numOfPax}${paramMarketerId}`;
        const res = await get('BFF', url, context);
        response = res.data;
        context.commit('SET_IS_COUPON_SUBMIT_STATE', false);
        return response;
      } catch (error) {
        if (String(error).includes('400')) {
          response = {
            type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
            title: 'One or more validation errors occurred.',
            status: 400,
            errors: {
              field: [
                'booking.invalid-coupon-price',
              ],
            },
          };
        } else {
          response = {
            type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
            title: 'One or more validation errors occurred.',
            status: 500,
            errors: {
              field: [
                'booking.invalid-coupon-number',
              ],
            },
          };
        }
        context.commit('SET_IS_COUPON_SUBMIT_STATE', false);
        return response;
      }
    },
    SEND_CLOSING_NOTICE: async (context, payload) => {
      let response = null;
      const { marketerId, agencyAuthorized } = context.state.agencyUsers;
      const paramMarketerId = (typeof marketerId !== 'undefined' && marketerId !== '') ? `&agency=${marketerId}` : '';
      try {
        if (payload.withData) {
          response = await postWithText('BFF', `/operation/bookingevent/${payload.bookDataID}/canceledWithBody?langCode=${languageList[context.state.language]}${paramMarketerId}`, payload.data, context);
        } else {
          response = await post('BFF', `/operation/bookingevent/${payload.bookDataID}/canceledWithoutBody?langCode=${languageList[context.state.language]}${paramMarketerId}`, undefined, context);
        }
        return response;
      } catch (e) {
        if (agencyAuthorized && e.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        return null;
      }
    },
    FETCH_DEAL_DATA: async (context, payload) => {
      const { dealType, moreDeal, forMaccabi } = payload;
      if (dealType === '') return null;
      /**
       * Old API to getting all deals
       * const response = await get('BFF', `/deal/inEffectHomeV2?from=1&to=10000&useEngAsSecondary=true&langCode=${languageList[context.state.language]}`, context);
       */

      /**
       *  New API to getting deals by dealType and moreDeal
       */
      const paramAgency = context.state.agencyUsers.marketerId ? `&agency=${context.state.agencyUsers.marketerId}` : '';
      // const url = forMaccabi ? '/deal/inEffectHomeV2?from=1&to=10000' : `/deal/inEffectHomeV3?dealType=${dealType}&moreDeals=${moreDeal}`;
      const url = forMaccabi ? '/deal/groupedHomeDealByHotel?from=1&to=10000' : `/deal/inEffectHomeV3?dealType=${dealType}&moreDeals=${moreDeal}`;
      const response = await get('BFF', `${url}${paramAgency}&langCode=${languageList[context.state.language]}`, context);
      setTimeout(() => { context.commit('SET_MORE_DEAL_DATA', moreDeal); }, 10);
      // const data = forMaccabi ? response.data.data : response.data;
      const { data } = response;
      // translate some properties
      await data.forEach(async (deal, idx) => {
        // transfors
        if (deal.transfers) {
          data[idx].translatedTransfors = [];
          const transfors = translateTextToAllLang(deal.transfers);
          transfors.forEach(({ text, to }) => {
            data[idx].translatedTransfors[to] = text;
          });
        }

        // price remark
        const priceRemarks = translateTextToAllLang(deal.priceRemark);
        if (priceRemarks) {
          priceRemarks.forEach(({ text, to }) => {
            data[idx].priceRemarkTrans[to] = text;
          });
        }
      });

      context.commit('SET_DEAL_DATA', data);
      return data;
    },
    FETCH_BANNER_IMAGES: async (context, payload) => {
      const { page } = payload;
      const { pageIdForBannerImage } = context.state;
      const { agencyAuthorized } = context.state.agencyUsers;

      if (pageIdForBannerImage && page === pageIdForBannerImage) return null;
      context.commit('SET_PAGE_ID_FOR_BANNER_IMAGES', page);

      try {
        const response = await get('BFF', `/pageContentsAggregate/byPage/${page}?langCode=${languageList[context.state.language]}`, context);
        context.commit('SET_BANNER_IMAGES', response.data);
        return response.data;
      } catch (e) {
        if (agencyAuthorized && e.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        return null;
      }
    },
    FETCH_BANNER_IMAGE_DESTINATION: async (context, payload) => {
      const { page } = payload;
      const { agencyAuthorized } = context.state.agencyUsers;
      try {
        const response = await get('BFF', `/pageContentsImage/${page}`, context);
        if (response.data !== '') context.commit('SET_BANNER_IMAGE_DEST', response.data);
        return response.data;
      } catch (e) {
        if (agencyAuthorized && e.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        return null;
      }
    },
    FETCH_DESTINATION_IMAGES: async (context) => {
      const response = await get('BFF', `/destinationEx/?from=1&to=1000&langCode=${languageList[context.state.language]}`, context);
      context.commit('SET_DESTINATION_IMAGES', response.data.data);
      return response.data.data;
    },
    FETCH_BANNER_TIMER: async (context) => {
      const response = await get('BFF', `/timerBanner/?from=1&to=1000&langCode=${languageList[context.state.language]}`, context);
      context.commit('SET_BANNER_TIMER', response.data.data);
      return response.data.data;
    },
    FETCH_DOMESTIC_DEAL: async (context) => {
      /**
       * Get all domestic deal
       * const response = await get('BFF', `/deal/fromFlyingCarpetM?langCode=${languageList[context.state.language]}`, context);
       */
      const response = await get('DOMESTIC', '/deal/inEffect/homepage/firstTab', context);
      context.commit('SET_DOMESTIC_DEAL_DATA', response.data);
      return response;
    },
    STORE_LANDING_URL: async (context, payload) => {
      const isLanding = VUE_APP_LANDING_PAGE_KEY in payload;
      const utmSource = payload[VUE_APP_LANDING_UTN_SOURCE_KEY] ? payload[VUE_APP_LANDING_UTN_SOURCE_KEY] : 'general';
      const phone = utmSource === 'google' ? VUE_APP_GOOGLE_PHONE_NUMBER : VUE_APP_FACEBOOK_PHONE_NUMBER;
      const dealType = payload.dealType ? payload.dealType : 'vacation_pack';
      const areaType = (payload.areaType && payload.areaType.toLowerCase() === 'true') || null;
      const isByDealType = ((typeof (payload.dealTypeCode) === 'object') && payload.dealTypeCode.length && true) || false;
      const destination = isLanding ? payload[VUE_APP_LANDING_PAGE_KEY] : '';
      const info = {
        destination: areaType ? destination : destination.toUpperCase(),
        utm_source: utmSource,
        phone,
        dealType,
        areaType,
        dealTypeCodes: isByDealType ? payload.dealTypeCode : null,
      };
      let response;

      context.commit('SET_LANDING_PAGE', isLanding);
      context.commit('SET_AREA_PAGE', areaType);
      if (isLanding) {
        context.commit('SET_LANDING_INFO', info);
        if (areaType) {
          const areaInfo = await get('BFF', `/area/${info.destination}`, context);
          response = await get('BFF', `/deal/inEffectLanding/byArea/${info.destination}?useEngAsSecondary=true&langCode=${languageList[context.state.language]}`, context);
          context.commit('SET_LANDING_AREA_DATA', areaInfo.data || null);
          context.commit('SET_SEARCH_TAB_CATEGORY_FOR_LANDING', areaInfo.data || null);
        // } else if (isByDealType) {
        //   const dealTypeCodeParam = payload.dealTypeCode.map((dt) => `dealTypeCode=${dt}`).join('&');
        //   response = await get('BFF', `/deal/inEffectLanding/byDealType/?useEngAsSecondary=true&${dealTypeCodeParam}&langCode=${languageList[context.state.language]}`, context);
        } else {
          response = await get('BFF', `/deal/inEffectLanding/byDest/${info.destination}?useEngAsSecondary=true&langCode=${languageList[context.state.language]}`, context);
        }
        context.commit('SET_LANDING_DEAL_DATA', response.data || []);
      }
    },
    FETCH_ALL_AREA_DATA: async (context) => {
      const allAreaInfo = await get('BFF', '/area', context);
      context.commit('SET_ALL_LANDING_AREA_DATA', allAreaInfo.data.data || null);
    },
    STORE_APP_DEAL_URL: async (context, payload) => {
      const isAppDealPage = payload.query?.channel?.indexOf(VUE_APP_CHANNEL_MOBILE_APP);
      context.commit('SET_APP_DEAL_PAGE', isAppDealPage > -1);
    },
    FETCH_APP_DEAL_PAGE_DATA: async (context, payload) => {
      const response = await get('BFF', `/deal/${payload.dealId}?langCode=${languageList[payload.lang]}`, context);
      context.commit('SET_APP_DEAL_DATA', response.data);
    },
    SEND_CONTACT_FORM: async (context, payload) => {
      // const response = await post('CONTACT', '/contact1.php', payload);
      const { marketerId } = context.state.agencyUsers;
      const paramMarketerId = (typeof marketerId !== 'undefined' && marketerId !== '') ? `?agency=${marketerId}` : '';
      const response = await post('BFF', `/lead/leadInfo/${paramMarketerId}`, payload, context);
      return response.data;
    },
    SEND_CONTACT_INFO_TO_ADMIN: async (context, payload) => {
      const response = await post('BFF', '/lead/footerContact/submit', payload, context);
      return response.data;
    },
    FETCH_LANDING_DESTINATIONS: async (context) => {
      const response = await get('BFF', '/deal/inEffectLanding/dests', context);
      context.commit('SET_LANDING_DESTINATIONS', response.data);
      return response;
    },
    FETCH_WHITE_LABEL: async (context, payload) => {
      try {
        const response = await get('BFF', `/agencyContents/byHost/${payload}?langCode=${languageList[context.state.language]}`);
        context.commit('SET_WHITE_LABEL', response.data);
        context.commit('SET_ODYSSEY_AGENT_CONTENT', response.data);
        context.commit('SET_PAYMENT_OPTION_PARAMS', { minPaymentsForCredit: response.data.minPaymentsForCredit, maxPayments: response.data.maxPayments });
        return response;
      } catch (error) {
        return { data: { status: 404 } };
      }
    },
    REDIRECT_TO_PRODUCT: (context) => {
      context.commit('SET_LOADING_STATE', true);
    },
    FETCH_HOTEL_LIST_BY_DEST: async (context) => {
      try {
        const response = await get('BFF', `/hotels?from=1&to=1000&langCode=${languageList[context.state.language]}`, context);
        context.commit('SET_HOTEL_LIST_BY_DEST', response.data.data);
        return response;
      } catch (error) {
        return { data: { status: 404 } };
      }
    },
    FETCH_HOTEL_LIST_BY_PACK: async (context) => {
      try {
        const response = await get('BFF', `/operation/packHotels?packCategId=Organize_tour_packages&langCode=${languageList[context.state.language]}`, context);
        context.commit('SET_HOTEL_LIST_BY_PACK', response.data);
        return response;
      } catch (error) {
        return { data: { status: 404 } };
      }
    },
    // --- footer part ---//
    FETCH_FOOTER_PAGES_LIST: async (context) => {
      try {
        const response = await get('BFF', '/footerItem/pageList', context);
        context.commit('SET_FOOTER_PAGES_LIST', response.data);
      } catch (error) {
        context.commit('SET_FOOTER_PAGES_LIST', null);
      }
    },
    FETCH_FOOTER_PAGE_CONTENT: async (context, payload) => {
      let response = null;
      try {
        response = await get('BFF', `/footerItem/${payload}`, context);
        context.commit('SET_FOOTER_PAGE_CONTENT', response.data);
      } catch (error) {
        context.commit('SET_FOOTER_PAGE_CONTENT', null);
      }
      return response;
    },
    FETCH_FOOTER_CATEGORY_LIST: async (context) => {
      try {
        const response = await get('BFF', '/footerCategory/activeOnly', context);
        context.commit('SET_FOOTER_CATEGORY_LIST', response.data);
      } catch (error) {
        context.commit('SET_FOOTER_CATEGORY_LIST', null);
      }
    },
    // --- start for hotel only ---//
    FETCH_LIST_COUNTRIES: async (context) => {
      try {
        const response = await get('HBA', '/hotel-content-api/locations/countries?fields=all&language=ENG&from=1&to=203', context);
        context.commit('SET_LIST_COUNTRIES', response.data.data);
        return response;
      } catch (error) {
        return { data: [] };
      }
    },
    FETCH_LIST_DESTINATION: async (context, payload) => {
      const response = await get('HBA', `/hotel-content-api/locations/destinations?fields=all&countryCodes=${payload}&language=ENG&from=1&to=100&useSecondaryLanguage=false`, context);
      context.commit('SET_HOTELONLY_DESTINATION', response.data.data);
      return response.data;
    },
    FETCH_LIST_HOTELS: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const {
        dest,
        fromDate,
        toDate,
        categoryId,
        adult,
        child,
        infant,
        ages,
      } = payload;
      context.commit('SET_CURRENT_CATEGORY', categoryId);
      const body = {
        stay: {
          checkIn: fromDate,
          checkOut: toDate,
        },
        occupancies: [
          {
            rooms: 1,
            adults: Number(adult),
            children: Number(child) + Number(infant),
            paxes: [],
          },
        ],
        destination: {
          code: dest,
        },
      };
      if (ages !== '') {
        const ageChildren = ages.split(',');
        ageChildren.forEach((age) => {
          body.occupancies[0].paxes.push({
            type: 'CH',
            age: Number(age),
          });
        });
      }

      const response = await post('HBA', '/hotel-api/hotels', body, context);
      context.commit('SET_PACKAGES', response.data);
      context.commit('SET_LOADING_STATE', false);
      return response.data;
    },
    FETCH_HOTEL_DETAIL_DATA: async (context, payload) => {
      const response = await get('HBA', `/hotel-content-api/hotels/${payload}/details?language=ENG`);
      // const response = await get('HBA', `/hotel-content-api/hotels?codes=${payload}&language=ENG&from=1&to=1000`);
      context.commit('SET_HOTEL_DETAIL_DATA', response.data.data);
      return response.data;
    },
    FETCH_PRODUCT_HOTEL_ONLY: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const { code, categoryId } = payload;
      context.commit('SET_CURRENT_CATEGORY', categoryId);
      const response = await context.dispatch('FETCH_HOTEL_DETAIL_DATA', code);
      context.commit('SET_LOADING_STATE', false);
      return response;
    },
    FETCH_FACILITIES_HOTEL_ONLY: async (context) => {
      context.commit('SET_LOADING_STATE', true);
      const response = await get('HBA', '/hotel-content-api/types/facilities?fields=all&language=ENG&from=1&to=1000&useSecondaryLanguage=True');
      context.commit('SET_FACILITIES_HOTEL_ONLY', response.data);
      context.commit('SET_LOADING_STATE', false);
      return response;
    },
    FETCH_HOTEL_AVAILABILITY: async (context, payload) => {
      const response = await post('HBA', '/hotel-api/hotels', payload, context);
      // context.state.product.activeHotel = response.data;
      return response;
    },
    FETCH_CHECK_COUPON_AVAILABILITY: async (context, payload) => {
      const { packageCategoryCode, packageDestinationCode, packageStartDate } = payload;
      const url = `/operation/checkCouponAvailability?packageCategoryCode=${packageCategoryCode}&packageDestinationCode=${packageDestinationCode}&packageStartDate=${packageStartDate}`;
      const response = await get('BFF', url, context);
      return response.data;
    },

    SEND_BOOKING_DATA_HOTEL_ONLY: async (context, payload) => {
      context.dispatch('STORE_BOOKING_DATA', payload);
      // try {
      //   const dataID = payload.sendingStage === 'init' ? '' : context.state.bookingDataID || window.sessionStorage.getItem('BookingDataID') || '';
      //   const response = await post('HBA', `/operation/storePage/${dataID}?langCode=${languageList[context.state.language]}`, bookingInfo, context);
      const response = {
        data: 'resting_id_2345234535',
      };
      context.commit('SET_BOOKING_DATA_ID', response.data);
      //   return {
      //     success: true,
      //     id: response.data,
      //   };
      // } catch (error) {
      //   return {
      //     success: false,
      //     error,
      //   };
      // }
      return {
        success: true,
        id: response.data,
      };
    },
    SEND_CHECK_RATE: async (context, payload) => {
      try {
        const response = await post('HBA', '/hotel-api/checkrates', payload, context);
        return {
          success: true,
          data: response.data,
        };
      } catch (error) {
        return {
          success: false,
          error,
        };
      }
    },
    // --- end for hotel only ---//
    // ---- Send Notification
    NOTIFY_ADMIN: async (context, payload) => {
      try {
        /*
          payload = {
            subject: "404 Error",
            body: "<h2>Page not found</h2><h3></h3>"
          }
        */
        let url = '/operation/notifyAdmin';
        if (payload.subject === 'ResponseCR2k') url += '?agency=2a3c58b3-8b0a-415d-8d5c-fc4aa2fa3ad0';
        await post('BFF', url, payload, context);
        return null;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    NOTIFY_ADMIN_404_ERROR: async (context, payload) => {
      try {
        const res = await post('BFF', '/operation/notifyAdmin/pageNotFound', payload, context);
        return res.data.redirectPath;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    // expert html to pdf or doc
    EXPORT_HTML_TO_WORD: async (context, payload) => {
      const data = new FormData();
      const { html } = payload;
      data.append('fileNameWithoutExt', 'testWordDoc');
      data.append('html', html);
      try {
        const res = await post('BFF', '/htmlExport/word', data, context, '', true);
        return res.data;
      } catch (error) {
        return null;
      }
    },
    EXPORT_HTML_TO_PDF: async (context, payload) => {
      const data = new FormData();
      const { html } = payload;
      data.append('fileNameWithoutExt', 'testPdfDoc');
      data.append('html', html);
      try {
        const res = await post('BFF', '/htmlExport/pdf', data, context, '', true);
        return res;
      } catch (error) {
        return null;
      }
    },

    // agent send message to client
    SEND_MESSAGE_TO_CLIENT: async (context) => {
      const bookingDataID = context.state.bookingDataID || window.sessionStorage.getItem('BookingDataID') || '';
      try {
        const res = await get('BFF', `/operation/sendFinalMsg/${bookingDataID}`, context);
        return res.data;
      } catch (error) {
        return 'error';
      }
    },
    SEND_CONFIRM_WITH_TERMS: async (context, payload) => {
      const res = await get('BFF', `/operation/acceptTerms/${payload}`, context);
      return res.data;
    },

    // fetch shorten url for share this
    FETCH_SHARED_SHORTEN_URL: async (context, payload) => {
      const { originUrl, expireDate } = payload;
      const { sharedShortenUrl, shredLongUrl } = context.state;
      if (originUrl === shredLongUrl) {
        context.commit('SET_SHARED_SHORTEM_URL', sharedShortenUrl);
      } else {
        try {
          const response = await get('BFF', `/shortenedUrl/?rawUrlPath=${originUrl}&expireDate=${expireDate}`, context);
          const { data } = response;
          const url = `${window.location.origin}${data}` || originUrl;
          context.commit('SET_SHARED_SHORTEM_URL', url);
          context.commit('SET_SHARED_LONG_URL', originUrl);
        } catch (error) {
          context.commit('SET_SHARED_SHORTEM_URL', originUrl);
          context.commit('SET_SHARED_LONG_URL', '');
        }
      }
    },
    FETCH_REVERSE_URL: async (context, payload) => {
      const { shortenedUrl } = payload;

      try {
        const response = await get('BFF', `/shortenedUrl/reverse?shortenedUrlPath=${shortenedUrl}`, context);
        const { data } = response;
        const url = data || '';
        return url;
      } catch (error) {
        return '';
      }
    },
  },
});
